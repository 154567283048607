<template>
<div ref="modalMobileData" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isMobileDataModalOpen ? 1 : 0, bottom: modalMobileDataHeight + 'px'}" :class="{active: isMobileDataModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<FolderIcon style="width: 27px; height: auto;" class="icon checkin-icon" />
			<span>Access this Resource</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closeMobileDataModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="modal-message-block">
		<MobileDataIcon class="data-icon" style="width: 90px; height: 90px;" />
		<h3>Mobile Data</h3>
		<p>We’ve detected that you are currently using mobile data. Accessing this resouce will use your mobile data, would you like to proceed?</p>
	</div>
	<Button icon="DownloadIcon" class="none-bottom btn btn-green btn-tick btn-icon btn-icon-right" text="Yes, I'm happy to use my data"></Button>
	<Button icon="ClockIcon" class="none-bottom btn btn-grey btn-tick btn-icon btn-icon-right" text="No, I'll wait until I have WiFi"></Button>
</div><!-- modal -->
</template>

<script>
import Button from '@/components/Button.vue';
import GreyCloseIcon from '@/assets/close-grey.svg';
import FolderIcon from '@/assets/folder.svg';
import MobileDataIcon from '@/assets/mobile-data.svg';
export default {
name: 'MobileDataOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			isMobileDataModalOpen: false,
			modalMobileDataHeight: null
		};
	},
	components: {
		Button,
		GreyCloseIcon,
		FolderIcon,
		MobileDataIcon
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalMobileData.clientHeight;
		}
	},
	methods: {
        closeMobileDataModal () {
        	this.isMobileDataModalOpen = !this.isMobileDataModalOpen;
        }
    },
    mounted () {
		this.modalMobileDataHeight = this.matchHeightModal;
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $red_dark;
				}
			}
			span {
				color: $red_dark;
			}
		}
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $red_dark;
				}
			}
			span {
				color: $red_dark;
			}
		}
	}
</style>
